.teaser-card {
  position: relative;
  height: 100%;
  background-color: $white-bg-color;
  padding: 50px 25px;
  box-sizing: border-box;
  min-height: 180px!important;
  @include breakpoint(tp) {
    padding: 15px;
    min-height: initial !important;
    padding: 30px;
  }

  &__text {}

  &__text .editable {
    overflow: hidden;
    max-height: 185px;
    @include breakpoint(tp) {
      max-height: 135px;
      padding-right: 20px;
    }
  }

  &__title {
    margin-bottom: 20px;
    overflow: hidden;
    max-height: 60px;
    @include breakpoint(tp) {
      margin-bottom: 50px;
      max-height: 120px;
    }
  }

  &__btn {
    margin-top: 15px;
    @include breakpoint(tp) {
      margin-top: 30px;
      position: absolute;
      bottom: 80px;
    }
  }

  &__image {
    height: 100%;
    @extend .bg-cover;
    @include breakpoint(tp) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.topic-card {
  border: 1px solid $dirty-border-color;
  padding: 60px 30px 0;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 40px;
  background: $white-bg-color;
  @include breakpoint(tp) {
    padding: 45px 30px 0;
    margin-bottom: 75px;
  }
  @include breakpoint(tl) {
    padding: 60px 30px 0;
    margin-bottom: 40px;
  }

  &--up {
    padding: 60px 15px 30px;
    text-transform: lowercase;

    h4 {
      line-height: 33px;
      font-size: 25px;
      font-weight: $font-weight-light;
      text-transform: lowercase;
      overflow: hidden;
      @include breakpoint(pp) {
        max-height: calc(33px*3);
        min-height: calc(33px*3);
      }
      @include breakpoint(tl) {
        max-height: calc(33px*6);
        min-height: calc(33px*6);
      }
      @include breakpoint(xl) {
        max-height: calc(33px*4);
        min-height: calc(33px*4);
      }
    }
  }

  &__header {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto;
    color: $highlight-dark-red-color;
    font-weight: 600;
    background-color: $dirty-bg-color;
    width: 100px;
    line-height: 100px;
    text-align: center;
  }

  &__title {
    //margin-bottom: 20px;
    text-transform: lowercase;
    color: $highlight-red-color;
  }

  p {
    overflow: hidden;
    @include breakpoint(tp) {
      min-height: calc(28px*6);
      max-height: calc(28px*6);
    }
  }

  &__button {
    position: relative;
    border: 2px solid $white-bg-color;
    margin: auto;
    width: 50px;
    height: 50px;
    line-height: 50px;
    transform: translateY(25px);
    @include breakpoint(tl) {
      transform: translateY(40px);
      width: 80px;
      height: 80px;
    }
    @include breakpoint(dt) {
      transform: translateY(50px);
      width: 90px;
      height: 90px;
    }
  }

  .btn-icon::before {
    line-height: 50px!important;
    @include breakpoint(tp) {
      line-height: 100px!important;
    }
  }
}

.big-card {
  cursor: pointer;
  display: block;
  position: relative;

  &__content {
    transition: all 0.3 ease-in-out;
  }

  &:hover {
    .btn-icon {
      border: 1px solid $highlight-red-color;

      &--red {
        &:before {
          transform: translateY(-100px);
        }

        &:after {
          transform: translateY(0px);
        }
      }
    }

    .big-card {
      &__content {
        border-color: $highlight-red-color;
      }

      &__image {
        transform: scale(1.2);
      }
    }
  }

  &::after {
    content: '';
    clear: both;
    display: block;
  }
  // LOGOS
  &__shrink {
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    background-color: $white-bg-color;
    width: 100%;
    float: left;
    overflow: hidden;
    height: 268px;
    @include breakpoint(tl) {
      //height: 400px;
      //width: calc(100% - 80px);
    }
    @include breakpoint(dt) {
      //width: calc(100% - 100px);
    }

    .big-card__image {
      float: none;
      margin: 0 auto;
      background-color: initial;
      height: 100%;
      transition: transform 0.3s;
    }
    /*.big-card__category {
      font-size: 25px;
    }*/
    &--type-two {
      height: auto;

      .wrapper {
        overflow: hidden;
      }

      .big-card__image {
        overflow: hidden;
        width: 100%;
        height: 280px;
        @include breakpoint(tp) {
          height: 300px;
        }
        @include breakpoint(tl) {
          height: 400px;
        }
      }
    }
  }

  &__image {
    width: 100%;
    float: left;
    height: 280px;
    @extend .bg-cover;
    background-color: $white-bg-color;
    background-size: contain;
    @include breakpoint(pl) {
      height: 320px;
    }
    /*@include breakpoint(tl) {
      height: 400px;
      width: calc(100% - 80px);
    }
    @include breakpoint(dt) {
      width: calc(100% - 100px);
    }*/
  }
  //red box
  &__box {
    width: calc(100% - 4px);
    height: 80px;
    position: absolute;
    top: 100%;
    background-color: $white-text-color;
    color: $highlight-dark-red-color;
    transition: 0.3s background-color;
    border: 2px solid $dirty-bg-color;
    // center
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;

    &:hover {
      &::after {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      background-color: #eb0029;
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      transition: -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      -webkit-transform-origin: top left;
      transform-origin: top left;
    }
    @include breakpoint(tl) {
      width: 280px;
    }
    @include breakpoint(dt) {
      height: 90px;
    }

    .btn-icon {
      border-left: 2px solid $dirty-bg-color;
    }
  }

  &__caption {
    width: 170px;
    max-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    @include breakpoint(dt) {
      max-height: 44px;
    }
  }

  &__content {
    transition: all 0.3s ease-in-out;
    background-color: white;
    padding: 15px 30px;
    position: relative;
    min-height: 60px;
    @include breakpoint(tp) {
      min-height: 100px;
    }

    .btn-icon {
      top: auto;
      bottom: 0;
    }

    .big-card__category {
      margin-bottom: 15px;
    }
  }

  &__category {
    text-transform: initial;
    font-size: 16px;
    line-height: 1.4;
    text-transform: uppercase;
    @include breakpoint(tl) {
      font-size: 18px;
    }
    @include breakpoint(dt) {
      //font-size: 20px;
    }
  }

  &__caption,
  .btn-icon {
    //display: inline-block;
    vertical-align: top;
  }

  &__title {
    //display: none;
    font-size: 14px;
    line-height: 1.4;
    text-transform: lowercase;
    @include breakpoint(tl) {
      //display: block;
      font-size: 14px;
    }
    @include breakpoint(dt) {
      font-size: 16px;
    }
  }
}
// PRODUCTS LIST ITEM

.small-card {
  transition: all 0.3s ease-in-out;
  //overflow: hidden;
  &:hover {
    .small-card__caption {
      border-color: $highlight-red-color;
    }

    .small-card__image {
      background-size: 100%;
    }
  }

  &__image {
    border: 2px solid $dirty-bg-color;
    border-bottom: none;
    //width: 100%;
    height: 270px;
    @extend .bg-cover;
    background-size: 80%;
    background-color: $white-bg-color;
    position: relative;
    transition: background-size 0.3s ease-in-out;
    overflow: hidden;
    @include breakpoint(tp) {
      height: 320px;
      //width: 100%;
      margin: auto;
    }
  }
  // SLIDER LOGOS
  &--little {
    box-sizing: border-box;
    background-color: $white-bg-color;

    .small-card__image {
      width: 80%;
      margin: 0 auto;
      background-color: initial;
    }

    .small-card__btn {
      transform: translateY(-30px);
    }
  }

  &__caption {
    border: 2px solid $dirty-bg-color;
    transition: all 0.3s ease-in-out;
    //margin: 10px 0 0;
    //padding-right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    padding: 15px;
    border-top: 2px solid $dirty-bg-color;
  }

  &__title {
    text-transform: initial;
    max-height: 40px;
    overflow: hidden;
    font-size: 17px;
    line-height: 20px;
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 17px;
    max-height: 17px;
    overflow: hidden;

    &--red {
      color: $highlight-red-color;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    overflow: hidden;
    bottom: 0;

    &:after,
    &:before {
      position: absolute;
      transition: transform 0.2s;
    }

    &:after {
      transform: translateY(100px);
    }

    &:hover {
      &::after {
        transform: translateY(0px);
      }

      &::before {
        transform: translateY(-100px);
      }
    }
  }
}

.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.products-filters {
  margin-bottom: 30px;
  @include breakpoint(tp) {
    margin-bottom: 60px;
    //padding-left: 25%;
  }

  .col-3 {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }

  .icon--search {
    position: absolute;
    top: 0;
    right: 0;
    height: 90px;
    line-height: 90px;
    width: 90px;
    font-size: 20px;
    border-left: 2px solid $white-bg-color;
    @include breakpoint(tp) {
      width: 50px;
    }
    @include breakpoint(tl) {
      width: 90px;
    }
  }

  input {
    &::placeholder {
      color: $light-text-color;
    }
  }

  input,
  select {
    box-sizing: border-box;
    position: relative;
    height: 90px;
    width: 100%;
    line-height: 50px;
    padding-right: 90px;
    padding-left: 15px;
    cursor: pointer;
    color: $light-text-color;
    // text-transform: uppercase;
    background-color: $dirty-bg-color;
    //box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
    border-bottom: 2px solid $white-bg-color;
    border-radius: none;
    text-transform: uppercase;
    @include breakpoint(tp) {
      border-right: 2px solid $white-bg-color;
      border-bottom: none;
      padding-right: 50px;
    }
    @include breakpoint(tl) {
      padding-right: 90px;
    }
  }

  select {
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: calc(100% - 15px) 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=");
  }

  .icon {
    &:after {
      content: '';
    }
  }
}

.filter-card {
  margin-bottom: 20px;
  @include breakpoint(tp) {
    margin-bottom: 0;
    padding-right: 40px;
  }

  &__title {
    position: relative;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    cursor: pointer;
    color: $light-text-color;
    text-transform: uppercase;
    background-color: $white-bg-color;

    &:nth-of-type(n+2) {
      margin-top: 2px;
    }

    &--active,
    &.active,
    &:hover {
      color: $highlight-red-color;
    }

    &--active {
      .icon--arrow-down::before {
        content: '\F2F8';
      }
    }

    .icon {
      right: 0;
      top: 0;
      position: absolute;
      background-color: $white-bg-color;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-left: 2px solid $dirty-border-color;
    }
  }

  &__options {
    display: none;
    padding-left: 20px;
    color: $text-color;
    margin: 5px 0;
    cursor: pointer;
  }

  &__option {
    padding: 5px 0;
    display: block;

    &:nth-of-type(n+2) {
      border-top: 2px solid $white-border-color;
    }

    &--active,
    &:hover {
      color: $highlight-dark-red-color;
    }
  }

  &__sub-options {
    display: none;

    &--active {
      display: block;
    }
  }
}
// DETAIL PRODUCT

.detail-card {
  &__article {
    margin-bottom: 40px;
    //max-height: 400px;
    //overflow: hidden;
    position: relative;
    transition: max-height 0.6s;
    color: $light-text-color !important;

    &.open {
      max-height: max-content;
    }

    li,
    p {
      font-size: 24px;
      line-height: 32px;
      font-weight: lighter;
      text-align: left !important;
      span{
        font-size: 24px !important;
        line-height: 32px;
        font-weight: lighter;
        text-align: left;
      }
    }



      span {
        //color: #555555 !important;
      //  font-weight: bold;
      }


    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #555555 !important;
      //font-weight: bold;
      text-transform: uppercase;
    }

    p {
      &:first-of-type {
        color: #555555 !important;
        font-weight: 400;
        text-transform: uppercase;
      }
    }

    li {
      margin-bottom: 10px;
    }

    ul {
      margin-top: 15px;
    }

    #openInfo {
      top: auto;
      right: 40px;
      position: absolute;
    }
    @include breakpoint(tp) {
      margin-bottom: 0;
      padding: 0 50px;
      @include breakpoint(tl) {
        padding: 0 100px;
      }
    }

    &.border {
      @include breakpoint(tp) {
        border-right: 1px solid $dirty-bg-color;
      }
    }
  }

  &__doc {
    ul {
      @include breakpoint(pl) {
        padding-left: 30px;
      }
    }

    li {
      a {
        margin-bottom: 30px;
        font-weight: lighter;
      }
    }
  }

  &__header {
    color: $highlight-red-color;
    margin-bottom: 60px;
    padding-left: 0;
    @include breakpoint(pl) {
      padding-left: 50px;
    }
    @include breakpoint(tl) {
      padding-left: 100px;
      margin-bottom: 90px;
    }

    h3 {
      color: $text-color;
      margin-top: 15px;
      text-transform: capitalize !important;
    }

    h5{
      color: $light-text-color;
      margin-top: 15px;
      text-transform: none;
    }

    h2{
      color: $light-text-color;
      font-weight: 700;
    }
  }
  /*p {
    min-height: 300px;
    padding-bottom: 30px;
    box-sizing: border-box;
  }*/
}
// news article

.article-card {
  position: relative;
  background-color: $white-text-color;

  &__date {
    width: 100px;
    height: 100px;
    display: table;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    top: -50px;
    right: 0;
    left: 0;
    background-color: $dirty-bg-color;
    color: $highlight-red-color;
    margin: auto;
    @include breakpoint(tp) {
      left: inherit;
      right: 50px;
    }

    p {
      display: table-cell;
      vertical-align: middle;
      max-height: 90px;
      overflow: hidden;
    }
  }

  &__image {
    height: 300px;
    @extend .bg-cover;
  }

  article {
    border: 1px solid $dirty-bg-color;
    padding: 40px;
    @include breakpoint(tp) {
      padding: 70px 40px 40px;
    }

    p {
      min-height: 90px;
      max-height: 90px;
      overflow: hidden;
      padding-top: 20px;
      color: $light-text-color;
    }
  }

  &__header {
    color: $light-text-color;
    font-weight: 300;
    @include breakpoint(tp) {
      max-height: 50px;
      overflow: hidden;
    }
  }

  &__btn {
    margin-top: 5px;
    float: right;
    color: $highlight-red-color;
  }
}

.authentication-card {
  &__element {
    &:nth-of-type(n+2) {
      padding-top: 20px;
    }
    @include breakpoint(tp) {
      &:nth-of-type(n+2) {
        padding-top: 0;
      }

      &:nth-of-type(2n-1) {
        padding-right: 20px;
      }

      &:nth-of-type(2n) {
        padding-left: 20px;
      }
    }
  }

  &__header {
    .title {
      color: $highlight-red-color;
    }

    p {
      margin-top: 20px;
    }
  }
}
