/**
 * Shapes mixins
 *
 * Author: José Osório
 * Copyright 2016
 */


/**
 * Triangle
 */
@mixin make-triangle($direction, $size, $pseudo:false){
    $directions: "top", "right", "bottom", "left";

    /* parse values */
    @if $direction == "up"
    {
        $direction: "top";
    }
    @else if $direction == "down"
    {
        $direction: "bottom";
    }

    @if in-list($direction, $directions)
    {
        @if $pseudo == true {
            content: '';
        }

        width: 0;
        height: 0;
        border: #{$size}px solid transparent;
        border-#{$direction}: none;
        display: block;

        /* color direction border */
        @if $direction == "top"
        {
            border-bottom-color: $border-color;

            &:hover {
                border-bottom-color: $highlight-color;
            }
        }
        @else if $direction == "right"
        {
            border-left-color: $border-color;

            &:hover {
                border-left-color: $highlight-color;
            }
        }
        @else if $direction == "bottom"
        {
            border-top-color: $border-color;

            &:hover {
                border-top-color: $highlight-color;
            }
        }
        @else if $direction == "left"
        {
            border-right-color: $border-color;

            &:hover {
                border-right-color: $highlight-color;
            }
        }
    }
    @else
    {
        @warn "`#{$direction}` is not a valid direction!";
    }
}

/**
 * Square
 */
@mixin make-square($side, $display: 'inline-block'){
    width: #{$side}px;
    height: #{$side}px;
    display: #{$display};
}

/**
 * Circle
 */
@mixin make-circle($diameter, $display: 'inline-block'){
    width: #{$diameter}px;
    height: #{$diameter}px;
    text-align: center;
    line-height: #{$diameter}px;
    border-radius: 50%;
    display: #{$display};
}
