.logos-list {
  .col-4:first-of-type {
    padding-top: 30px;
  }

  .col-4:last-of-type {
    padding-top: 30px;
  }
}

.topics-list {
  &--up {
    padding-top: 90px;

    .topics-list__element {
      &:nth-of-type(3n) {
        padding-left: 0;
      }
      @include breakpoint(tp) {
        &:nth-of-type(2n-2) {
          padding-right: 15px;
        }

        &:nth-of-type(2n) {
          padding-left: 15px;
        }

        &:nth-of-type(n+3) {
          padding-top: 80px;
        }
      }
      @include breakpoint(tl) {
        &:nth-of-type(2n-2) {
          padding-right: 0;
        }

        &:nth-of-type(2n) {
          padding-left: 0;
        }

        &:nth-of-type(4n-3) {
          padding-right: 50px;
        }

        &:nth-of-type(4n) {
          padding-left: 50px;
        }

        &:nth-of-type(4n-1),
        &:nth-of-type(4n-2) {
          padding-left: 25px;
          padding-right: 25px;
        }

        &:nth-of-type(n+3) {
          padding-top: 0;
        }
      }
    }
  }

  &__element {
    //padding: 0px 30px;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 60px;
    }
    @include breakpoint(tl) {
      padding-right: 30px;
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    /*   &:nth-of-type(n+2) {
      padding-top: 60px;
    }
    @include breakpoint(tp) {
      &:nth-of-type(n+2) {
        padding-top: 0;
      }

      &:nth-of-type(3n-1) {
        padding-left: 15px;
        padding-right: 15px;
      }

      &:nth-of-type(3n-2) {
        padding-right: 30px;
      }

      &:nth-of-type(3n) {
        padding-left: 30px;
      }
    }
    @include breakpoint(tl) {
      &:nth-of-type(n+2) {
        padding-top: 0;
      }

      &:nth-of-type(3n-1) {
        padding-left: 25px;
        padding-right: 25px;
      }

      &:nth-of-type(3n-2) {
        padding-right: 50px;
      }

      &:nth-of-type(3n) {
        padding-left: 50px;
      }
    }*/
  }
}

.partners-info {
  &__element {
    .big-card__image {
      background-size: contain;
    }

    .big-card__shrink {
      padding: 60px 60px 90px;
    }
  }
}

.industries-category,
.partners-info,
.products-category,
.solutions-list {
  &__element {
    padding-bottom: 150px;

    &:last-of-type {
      padding-bottom: 90px;
    }
    @include breakpoint(tp) {
      padding-right: 30px;
      padding-bottom: 90px;
    }
  }

  &--slider {
    .solutions-list__element {
      padding-top: 0;
      @include breakpoint(tp) {
        padding-left: 50px;
      }
    }
  }
}

.partners-info,
.products-category {
  &__element {
    @include breakpoint(tp) {
      padding-bottom: 180px;

      &:nth-of-type(2n) {
        padding-right: 0;
      }

      &:nth-last-child(1) {
        padding-bottom: 90px;
      }
    }
    @include breakpoint(tl) {
      &:nth-of-type(2n) {
        padding-right: 30px;
      }

      &:nth-of-type(3n) {
        padding-right: 0;
      }

      &:nth-last-child(1) {
        padding-bottom: 90px;
      }
    }
  }

  .big-card {
    &__shrink {
      padding: 30px;
      border: 2px solid $dirty-bg-color;
    }

    &__box {
      border-top: none;
    }
  }
}

.solutions-list {
  /* ---- isotope ---- */
  .grid {
    margin: -15px;
  }
  /* clear fix */
  .grid:after {
    content: '';
    display: block;
    clear: both;
  }
  /* ---- .element-item ---- */
  .element-item {
    position: relative;
    float: left;
    width: calc(100% - 30px);
    margin: 15px;
    @include breakpoint(tp) {
      width: calc(50% - 30px);
    }
    @include breakpoint(dt) {
      width: calc(33.333333% - 30px);
    }
  }

  .element-item > * {
    margin: 0;
    padding: 0;
  }

  .big-card {
    &__image {
      background-size: cover !important;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 2px solid $dirty-bg-color;
    }

    &__category {
      margin-bottom: 0;
      color: $light-text-color;
      text-transform: none;
    }

    &__title {
      color: $highlight-red-color;
      text-transform: uppercase;
      //margin-bottom: 15px;
    }
  }
}

.partners-list {
  height: 120px;
  margin-top: 60px;

  &__element {
    position: relative;
    height: 120px;
    margin: 0 10px;
  }

  &__image {
    filter: grayscale(100%);
    width: 100%;
    opacity: 0.4;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    @extend .bg-cover;
    background-size: contain;
    background-color: $white-bg-color;
  }
}

.partners-info {
  &__element {
    &:nth-of-type(n+2) {
      padding-top: 30px !important;
    }
    @include breakpoint(tp) {
      &:nth-of-type(n+2) {
        padding-top: 0 !important;
      }
    }
  }
}

.products-list {
  margin: -15px;

  &__element {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;

    &:nth-of-type(n+2) {
      padding-top: 60px;
    }
    @include breakpoint(pl) {
      &:nth-of-type(n+2) {
        padding-top: 0;
      }

      &:nth-of-type(n+3) {
        padding-top: 60px;
      }
    }
    @include breakpoint(tl) {
      &:nth-of-type(n+3) {
        padding-top: 0;
      }

      &:nth-of-type(n+5) {
        padding-top: 60px;
      }
    }
  }
}
// .partners-info,
// .products-list

.solutions-slider {
  // height: 340px;
  &__element {
    box-sizing: border-box;

    &:nth-of-type(n+2) {
      padding-top: 30px;
    }
    @include breakpoint(pl) {
      padding-right: 1%;
      padding-left: 1%;

      &:nth-of-type(n+2) {
        padding-top: 0;
      }

      &:nth-of-type(n+3) {
        padding-top: 30px;
      }
    }
    @include breakpoint(tp) {
      padding-right: 3%;
      padding-left: 3%;
    }
    @include breakpoint(tl) {
      &:nth-of-type(n+3) {
        padding-top: inherit;
      }

      &:nth-of-type(n+4) {
        padding-top: 30px;
      }
    }
  }
}

.btns-list {
  width: 280px;
  margin: 0 auto;
  @include breakpoint(tp) {
    width: 600px;
  }

  &__element {
    box-sizing: border-box;

    &:nth-of-type(n+2) {
      padding-top: 20px;
    }
  }
}
// news list

.news-list {
  padding-top: 20px;

  &__element {
    box-sizing: border-box;

    &:nth-of-type(n+2) {
      margin-top: 120px;
    }
  }
}
// news detail photos

.photos-list-wrapper {
  position: relative;
}

.photos-list {
  &__arrows {
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 50px;
    width: 104px;
    @include breakpoint(tl) {
      bottom: -40px;
      height: 80px;
      width: 164px;
    }
    @include breakpoint(dt) {
      bottom: -50px;
      height: 100px;
      width: 184px;
    }
  }

  &__image {
    @extend .bg-cover;
    height: 220px;
    @include breakpoint(tp) {
      height: 550px;
    }
  }

  &__video {
    cursor: pointer;
    transition: 0.3s all ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 15px;
      right: 15px;
      top: 0;
      background: rgba(0, 0, 0, 0.4);
    }

    &:after {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      content: '\F3AA';
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
      margin: auto;
      font: 36px "Material-Design-Iconic-Font";
      font-size: 50px;
      line-height: 30px;
      text-align: center;
      color: #cf102d;
    }
  }

  &__item {
    position: relative;

    &:nth-of-type(n+2) {
      margin-top: 40px;
    }
    @include breakpoint(tp) {
      padding-right: 15px;
      padding-left: 15px;

      &:nth-of-type(2n) {
        margin-top: inherit;
      }

      &:nth-of-type(n+3) {
        margin-top: 60px;
      }
    }
  }
}

.filters {
  width: 100%;
  overflow: auto;
  @include breakpoint(tp) {
    width: auto;
  }

  .button-group {
    margin-top: 30px;
    width: max-content;
    @include breakpoint(tp) {
      //margin-top: 0;
      //width: auto;
    }
  }

  button {
    cursor: pointer;
    margin-right: 15px;
    text-transform: capitalize;
    color: $text-color;

    &:last-of-type {
      margin-right: 0;
    }

    &.is-checked {
      border-bottom: 1px solid $text-color;
    }
  }
}
