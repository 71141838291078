/**
 * Accordion component
 *
 * Author: José Osório
 * Copyright 2016
 */

.accordion {
    /* record */
    &__rec{
        margin-bottom: 6px;
    }

    /* header */
    &__header {
        padding: 10px 50px 10px 25px;
        line-height: 26px;
        font-size: 18px;
        background-color: #f4f4f4;
        cursor: pointer;
        position: relative;
        transition: background-color 0.4s;

        /* arrow */
        &::before {
            width: 0;
            height: 0;
            margin: auto;
            content: '';
            border-top: 6px solid $dark-border-color;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            display: block;
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            transition: transform 0.4s;
        }

        @include breakpoint(tl){
            padding: 15px 55px 15px 30px;
            line-height: 28px;

            /* arrow */
            &::before {
                right: 20px;
            }
        }
    }

    /* content */
    &__content {
        padding: 25px;
        display: none;

        @include breakpoint(tl){
            padding: 30px;
        }
    }

    /* hover / active */
    .accordion__header:hover,
    .accordion--active .accordion__header{
        background-color: transparent;
    }

    .accordion--active .accordion__header{
        &::before {
            transform: rotateZ(180deg);
        }
    }
}
