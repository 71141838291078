/**
 * Typography styles
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Inline elements
 */
[href]{
	cursor: pointer;
}
a{
	transition: color .3s 0s;
}


/**
 * Block elements
 */
p{
	margin-bottom: 0;
}


/**
 * Headings
 */
h1{
	font-size: 2.2em;
}
h2{
	font-size: 1.3em;

	@include breakpoint(pl){
		font-size: 1.6em;
	}
	@include breakpoint(tl){
		font-size: 1.9em;
	}
	@include breakpoint(dt){
		font-size: 2.2em;
	}
}
h3{
	font-size: 1.35em;

	@include breakpoint(tp){
		font-size: 1.4em;
	}
}
h4{
	font-size: 1.3em;

	@include breakpoint(tp){
		font-size: 1.4em;
	}
}
h5{
	font-size: 1.2em;
}
h6{
	font-size: 1em;
}


/**
 *	Text alignment
 */
@include make-text-align();
@include make-text-align(pl);
@include make-text-align(tp);
@include make-text-align(tl);
@include make-text-align(dt);


/**
 *	Text transformation
 */
.text-upper{
	text-transform: uppercase;
}
.text-lower{
	text-transform: lowercase;
}
.text-cap{
	text-transform: capitalize;
}


/**
 *	Editable content
 */
.editable{
	*{
		max-width: 100% !important;
		line-height: inherit !important;
		font-family: inherit !important;
		word-break: break-word !important;
	}

	p{
		margin: 0 0 1em 0 !important;
	}

	ul,
	ol{
		list-style-position: inside;
	}

	ul{
		list-style-type: disc;
	}

	ol{
		list-style-type: decimal;
	}
}


/**
 *	Messages
 */
.message{
	padding: 12px 15px;
	border-width: 1px;
	border-style: solid;

	span{
		display: block;
	}

	/* info */
	&--info{
		border-color: #035292;

		b{
			color: #035292;
		}
	}
	/* success */
	&--success{
		border-color: #0aa412;

		b{
			color: #0aa412;
		}
	}
	/* error */
	&--error{
		border-color: #de033a;

		b{
			color: #de033a;
		}
	}
	/* warning */
	&--warn{
		border-color: #fd8300;

		b{
			color: #fd8300;
		}
	}
}
