.slideshow {
  position: relative;

  &__wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
    @include breakpoint(dt) {
      align-items: center;
    }
  }

  &--solutions {
    .slideshow__image {
      background-size: contain;
    }
  }
  // TOP SLIDESHOW
  &--teaser {
    // height: 340px;
  }
  // TOP SLIDESHOW
  &--full {
    height: 260px;
    margin-bottom: 30px;
    @include breakpoint(tp) {
      //height: calc(100vh - 236px);
      padding-top: 40px;
      margin-bottom: 120px;
      height: 580px;
    }

    .slideshow__content {
      //height: calc(100vh - 236px);
      @include breakpoint(tp) {
        //height: calc(100vh - 236px);
      }
    }

    .slideshow__image,
    iframe {
      @include breakpoint(tl) {
        display: initial;
        //width: calc((100% - (100% - 960px) / 2) - 200px) !important;
      }
      @include breakpoint(dt) {
        //width: calc((100% - (100% - 1200px) / 2) - 180px) !important;
      }
      @include breakpoint(xxl) {
        //width: calc((100% - (100% - 1200px) / 2)) !important;
      }
    }

    .slideshow__dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -30px;
      z-index: 5;
      max-width: 100%;
      //height: 35px;
      overflow: hidden;
      @include breakpoint(tp) {
        max-width: 180px;
      }
      @include breakpoint(dt) {
        bottom: -50px;
      }

      > ul {
        position: relative!important;
        bottom: initial!important;
        left: initial!important;

        li {
          display: none;
          font-family: $primary-font;
          font-size: 18px;
          text-align: center;

          &.slick-active {
            display: block;
          }
        }
      }
    }

    .slideshow__arrows {
      position: absolute;
      z-index: 10;
      left: 0;
      width: 100%;
      bottom: 0;
      height: 30px;
      overflow: hidden;
      top: 0;
      @include breakpoint(tp) {
        width: 180px;
        top: initial;
        bottom: -30px;
      }
      @include breakpoint(dt) {
        bottom: -50px;
      }

      .slick-arrow {
        color: $highlight-red-color;
        width: 35px;
        height: 35px;

        &:hover {
          color: $highlight-dark-red-color;
        }

        &.btn-arrow--right {
          float: right;
        }
      }
    }
  }

  &__slider,
  .slider-for-banner {
    position: relative;
    height: 100%;
    max-width: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
  }
  /* image */
  &__image,
  iframe {
    //width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    @extend .bg-cover;
    background-color: $white-bg-color;
    transition: top 0.8s;
    backface-visibility: hidden;
    @include breakpoint(dt) {
      width: calc(100% - 280px);
    }
  }
  // CAPTION BOX
  &__caption-wrap {
    position: relative;
    @include breakpoint(tp) {
      // sara code
      //padding-top: 20vh;
    }
  }

  &__caption {
    // sara code
    //position: absolute;
    //bottom: -310px;
    ///height: 40px;
    //left:0;
    //right:0;
    //margin: 15px auto 0;
    // padding-top:50px;
    @include breakpoint(tp) {
      //margin:initial;
      //width: 240px!important;
      //bottom: 0;
      //position: relative;
      //height: calc(100vh - 236px);
      //padding-top: 0;
      // padding-top:100px;
      //width: 400px!important;
      // height: 450px !important;
    }
    @extend .banner-teaser__caption;
    position: absolute;
    left: 0;
    //width: 400px !important;
    @include breakpoint(xxl) {
      //  left: -180px;
    }
  }

  .slider-for-banner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .slider-nav-banner {
    width: 100%;
    position: relative;
    color: $dark-text-color;
    // background-color: $white-bg-color;
    // padding: 0 30px 20px;
    display: none;
    @include breakpoint(tp) {
      display: block;
      // padding: 0 50px 20px;
    }
  }

  &__header {
    width: 100%;
    position: relative;
  }
  // partners slider
  &--small {
    height: 170px;

    .slideshow__image {
      background-size: contain;
    }
  }
  // product detail slider
  &--detail {
    position: relative;
    height: 400px !important;
    @include breakpoint(tp) {
      height: 510px !important;
    }

    .slider-for {
      width: 100%;
      height: 250px !important;
      @include breakpoint(tp) {
        height: 400px !important;
      }
    }

    .slider-nav {
      padding-top: 10px;
      height: 100px;

      &__element {
        cursor: pointer;
        height: 100px;
        margin: 0 5px;
      }

      .slideshow__image {
        background-size: contain;
      }
    }

    .slideshow__arrows {
      margin-top: 10px;
      width: 100%;

      span {
        width: 50px;
        height: 50px;
        line-height: 50px;
        color: $text-color;
        cursor: pointer;

        &:hover {
          color: $highlight-red-color;
        }
      }

      &--inline {
        display: inline-block;
      }
    }
  }
}
// SIMILAR PRODUCTS SLIDER ARROWS

.slideshow__long-arrows {
  &--inline {
    display: inline-block;
    line-height: 40px;
    width: 100%;
    @include breakpoint(tp) {
      line-height: 60px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      .to-left {
        left: 0;
      }

      .to-right {
        right: 0;
      }
    }

    .icon {
      cursor: pointer;
      font-size: 35px;
      color: $white-text-color;

      &:hover {
        color: $highlight-dark-red-color;
      }
    }

    &--grey {
      .icon {
        color: $dirty-bg-color;
      }
    }
  }
}

.slider-title-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.slider-title {
  @extend .banner-teaser__header;
  /*display: table;
  overflow: hidden;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, .9);
  padding: 25px;
  min-height: 205px;
  @include breakpoint(tp) {
    //min-width: 400px;
    //padding: 0 40px;
  }*/
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 40px;
    min-height: calc(40px * 2);
    max-height:calc(40px * 2);

    span {
      color: $highlight-red-color;
    }
    @include breakpoint(dt) {
      line-height: 58px;
      min-height: calc(58px * 2);
      max-height:calc(58px * 2);
    }
  }
}

.slideshow__dots {
  ul {
    position: absolute;
    left: 0;
    bottom: -50px;
    z-index: 2;
    width: auto;
    height: 30px;
    overflow: hidden;

    li {
      display: inline-block;

      &:nth-of-type(n+2) {
        margin-left: 10px;
      }

      &.slick-active {
        button {
          background-color: $highlight-red-color;
        }
      }
    }

    li button {
      width: 15px;
      height: 15px;
      border: 1px solid $highlight-red-color;
      background-color: transparent;

      &:hover {
        background-color: $highlight-red-color;
      }
    }
  }
}
//  slider product detail pagination

.slideshow__pagination {
  max-width: 100%;
  text-align: center;
  line-height: 50px;
  max-height: 50px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.slideshow-teaser {
  position: relative;
  margin-bottom: 60px;

  .container {
    @include breakpoint(dt) {
      width: calc((100vw - (100% - 1200px) / 2)) !important;
      float: right;
    }
  }

  &--detail {
    cursor: grabbing;
    margin-bottom:105px;
    @include breakpoint(tp) {
    margin-bottom:135px;
    }

    .slideshow-teaser__figures {
      border: 1px solid $dirty-bg-color;
      background-color: white;
      margin: auto;
      transition: 0.3s all;

      .slideshow-teaser__img {
        opacity: 1;
        background-size: cover;
        width: 100% !important;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &--chronogram {
    height: auto!important;
    @include breakpoint(tp) {
      height: 550px!important;
    }
    @include breakpoint(tl) {
      height: 450px!important;
    }


    .slideshow-teaser__wrap {
      @include breakpoint(tp) {
        width: 50% !important;
      }
    }
    .slideshow-teaser__images-wrap{

        margin-left: 0% !important;

    }

    .slideshow-teaser__image--wide,
    .slideshow-teaser__text-card,
    .slideshow-teaser__wrap {
      opacity: 1 !important;
      @include breakpoint(tp) {
        height: 550px!important;
      }
      @include breakpoint(tl) {
        height: 450px!important;
      }
    }

    .teaser-card__title {
      max-height: 85px;
      margin-bottom: 20px;
    }

    .teaser-card__text .editable {
      max-height: initial;
      @include breakpoint(pl) {
        padding-right: 0;
        max-height: 325px;
      }
      @include breakpoint(tl) {
        max-height: 275px;
      }
    }
  }

  &__images-wrap,
  &__images-wrap1,
  &__images-wrap2 {
    margin: auto;
    width: 100%!important;
    @include breakpoint(tp) {
      margin-left: 50%;
    }
    @include breakpoint(tl) {
      margin-left: 33.3%;
    }
    @include breakpoint(xxl) {
      margin-left: 25%;
    }
  }

  &__caption,
  &__caption1,
  &__caption2 {
    position: relative;
  }

  &__wrap {
    margin: 0 auto;
    position: relative;
    z-index: 1;
    border: 2px solid $dirty-border-color;
    border-top: none;
    @include breakpoint(tp) {
      border: 2px solid $dirty-border-color;
      position: absolute!important;
      top: 0;
      left: 0;
      height: 468px;
      width: 50%!important;
    }
    @include breakpoint(tl) {
      width: 33.3%!important;
    }
    @include breakpoint(xxl) {
      width: 25% !important;
    }
  }

  &__text-card {
    width: 100%;
    padding-top: 50px !important;
    @include breakpoint(tp) {
      padding-top: initial;
      height: 468px !important;
    }
  }

  &__images {
    background-color: white;
    margin: auto;
    transition: 0.3s all;
    //width: 250px;
    width: 100%;
    border: 2px solid $dirty-border-color;
    border-left: none;
    @include breakpoint(tp) {
      //width: calc(700px / 2) !important;
    }
    @include breakpoint(tl) {
      //width: calc(960px / 3) !important;
    }
    @include breakpoint(dt) {
      //  width: 468px;
    }
    @include breakpoint(xxl) {
      //margin-left: 25%;
      //width: calc((100vw - (100vw - 1200px) / 2) / 4) !important;
    }

    &.slick-current {
      .slideshow-teaser__img {
        opacity: 1;
      }
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__img {
    background-size: contain;
    margin: auto;
    @include breakpoint(tl) {
      width: 80%!important;
    }
  }

  &__image--wide {
    position: relative;
    width: 490px;
    height: 200px !important;
    @extend .bg-cover;
    opacity: 0.1;

    &.slick-current {
      opacity: 1;
    }
    @include breakpoint(pl) {
      height: 300px !important;
    }
    @include breakpoint(tp) {
      height: 400px !important;
      width: 510px;
    }
  }

  &__image,
  &__img {
    //width: 250px;
    position: relative;
    height: 200px !important;
    @extend .bg-cover;
    opacity: 0.3;

    &.slick-current {
      opacity: 1;
    }
    @include breakpoint(tp) {
      height: 468px !important;
    }
  }

  &__arrows,
  &__arrows2 {

    position: absolute;
    margin: 0 auto;
    height: 50px;
    width: 104px;
    z-index: 2;
    bottom: -25px;

    transform: translateX(-50%);
    left: 50%;
    transform: translateX(-50%);
    @include breakpoint(tp) {
        left: auto;
        transform: none;
        right: -53px;
    }
    @include breakpoint(tl) {
      margin-top: 0;
      top: auto!important;
      left: auto!important;
      width: 80px;
      height: 80px;
      line-height: 80px;
      right: -83px;
      width: 164px;
      bottom: -40px;
    }
    @include breakpoint(dt) {
      height: 90px;
      width: 184px;
      bottom: -45px;
      right: -93px;
    }

  /*  .btn-arrow {
      color: $highlight-dark-red-color;

      &:first-of-type {
        background-color: $white-text-color;
        border-right: 2px solid $dirty-border-color;

        &:after {
          content: '',;
        }

        &:hover {
          background-color: $highlight-dark-red-color;
          color: $white-text-color;
        }
      }

      &:last-of-type() {
        border-left: none;
        background-color: $white-text-color;

        &:hover {
          background-color: $highlight-dark-red-color;
          color: $white-text-color;
        }
      }
    }*/
  }
}

.slick-arrow {
  &:after {
    content: '';
  }
}


.slider-controls{
  display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -25px;
    width: 100%;

    @include breakpoint(tl) {
      bottom: -45px;
    }
}
