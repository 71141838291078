/**
 * Styles reset
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Body settings
 */
body
{
	margin: 0;
	padding: 0;
}

/**
 * Forms & inputs
 */
input, textarea, select, button
{
	padding: 0;
	color: inherit;
	font: inherit;
	letter-spacing: inherit;
	outline: none;
	background: none;
	border: none;
}
textarea{
	resize: none;
	overflow: hidden;
}
button{
	padding: 0;
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6
{
	margin: 0;
	padding: 0;
}

/**
 *	Inline elements
 */
a
{
	color: inherit;
	text-decoration: none;
	outline: none;
}

/**
 *	Block elements
 */
figure{
	margin: 0;
}

hr{
	border-width: 1px 0 0 0;
	border-style: solid;
}

p{
	margin: 0;
}

ul,
ol{
	margin: 0;
	padding: 0;
	list-style: none;
}
