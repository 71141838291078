/**
 * Floats mixins
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * To mixin
 */
@mixin to($side, $breakpoint)
{
    // Check if side is an accepted value
    @if($side == left or $side == right)
    {
        // Retrieves the value from the key
        $value: map-deep-get($breakpoints, $breakpoint, min);

        // If the value exist on the map
        @if($value != null)
        {
            // Prints a media query based on the value
            @media (min-width: $value)
            {
                float: $side !important;
            }
        }
        // If the key doesn't exist in the map
        @else
        {
            @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Please make sure it is defined in `$breakpoints` map.";
        }
    }
    @else
    {
        @warn "`#{$side}` side doesn't exist. Please set a value equal to `right` or `left`.";
    }
}
