/**
 * Grid mixins
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Make grid
 */
@mixin make-grid($breakpoint:null){
    @if $breakpoint != null{
        @include breakpoint($breakpoint){
            @for $i from 1 through map-get($grid, cols){
                .col-#{$breakpoint}-#{$i}{
                    width: percentage($i / map-get($grid, cols));
                    box-sizing: border-box;
                    float: left;
                }
            }
        }
    }@else{
        @for $i from 1 through map-get($grid, cols){
            .col-#{$i}{
                width: percentage($i / map-get($grid, cols));
                box-sizing: border-box;
                float: left;
            }
        }
    }
}
