/**
 * Text mixins
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Make text alignments
 */
@mixin make-text-align($breakpoint:null){
    @if $breakpoint != null{
        @include breakpoint($breakpoint){
            .text-#{$breakpoint}-left{
            	text-align: left !important;
            }
            .text-#{$breakpoint}-right{
            	text-align: right !important;
            }
            .text-#{$breakpoint}-center{
            	text-align: center !important;
            }
            .text-#{$breakpoint}-justify{
            	text-align: justify !important;
            }
        }
    }@else{
        .text-left{
        	text-align: left !important;
        }
        .text-right{
        	text-align: right !important;
        }
        .text-center{
        	text-align: center !important;
        }
        .text-justify{
        	text-align: justify !important;
        }
    }
}
