/**
 * Menus components
 *
 * Author: José Osório
 * Copyright 2016 - 2017
 */

/**
 * Drop down menu
 */
.drop-menu{
    height: 70px;
    line-height: 70px;
    text-align: center;
    box-sizing: border-box;

    > li{
        height: inherit;
        line-height: inherit;
        box-sizing: border-box;
        display: inline-block;
        cursor: pointer;
        position: relative;
        float: left;

        > a{
            height: inherit;
            line-height: inherit;
            font-size: 1.3em;
            display: block;
        }


        a{
            padding-right: 20px;
            padding-left: 20px;
            display: block;
            box-sizing: border-box;
        }

        > ul{
            line-height: 1.5;
            min-width: 100%;
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;

            li{
                border-bottom: 1px solid #cccccc;
            }

            a{
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        /* hover */
        &:hover{
            > ul{
                display: block;
            }
        }
    }
}


/**
 * Vertical menu
 */
.v-menu{
    > li{
        position: relative;

        a{
            display: block;

            &:hover,
            &.v-menu-active{
                color: $highlight-color;
            }
        }

        > a{
            height: 40px;
            line-height: 40px;
            margin-right: 40px;
            display: block;
        }

        /* toggler */
        .v-menu-toggler{
            width: 40px;
            height: 40px;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;

            &:after{
                @include make-triangle("down", 5);
                @extend .abs-center;
                transition: transform .4s;
            }

            &:hover{
                color: $highlight-color;
            }
        }

        /* submenu */
        > ul{
            padding-left: 35px;
            display: none;

            a{
                padding-top: 5px;
                padding-right: 15px;
                padding-bottom: 5px;
            }
        }

        /* active submenu */
        &.v-menu-active{
            .v-menu-toggler{
                &:after{
                    transform: rotateZ(180deg);
                }
            }
        }
    }
}
