/* Typography */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');
@import url("https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css");

/* base styles (import before fw files!) */
@import "app/base/config.scss";

/* vendor */
@import "vendor/fw/fw.scss";

/* layout */
@import "app/layout/layout.scss";
@import "app/layout/typography.scss";


/* components */
@import "app/components/navbar.scss";
@import "app/components/buttons.scss";
@import "app/components/form.scss";
@import "app/components/slideshows.scss";
@import "app/components/cards.scss";
@import "app/components/banners.scss";
@import "app/components/lists.scss";
// @import "app/components/accordion.scss";
// @import "app/components/banner.scss";
// @import "app/components/icons.scss";
// @import "app/components/inputs.scss";

/* partials */
@import "app/partials/user.scss";
@import "app/partials/contacts.scss";
// @import "app/partials/articles.scss";
// @import "app/partials/testimonials.scss";
