body {
  // background-color: #414042;
  background-color: $white-bg-color;
  height: 100%;

  * {
    letter-spacing: 0.06em;
  }
}
/**
 * Container
 */
.container {
  position: relative;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.page-wrap {
  overflow: hidden;
}
/**
 * Section
 */
.section {
  padding: 50px 0 30px;
  @include breakpoint(tp) {
    padding: 60px 0;
  }

  &--less {
    padding: 30px 0 90px;
  }

  &--pb120 {
    padding-bottom: 120px;
  }

  &--none {
    padding-bottom: 30px !important;
  }

  &__wrap {
    @include breakpoint(tp) {
      padding: 32px 68px 52px;
    }
    @include breakpoint(dt) {
      padding: 66px 106px 100px;
    }
  }

  &__header {
    position: relative;
    padding-top: 0;
    @include breakpoint(tp) {
      padding-bottom: 60px;
    }
    @include breakpoint(tl) {
      padding-bottom: 90px;
    }

    &.filters {
      flex-direction: column;
      @include breakpoint(tl) {
        flex-direction: row;
      }
    }

    &--red {
      color: $highlight-red-color;
    }

    &--center {
      text-align: center;
    }

    &--full-justify {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__bar {
      width: 100%;
      height: 2px;
      background-color: $white-bg-color;
      border: none;
      margin-top: 20px;
    }

    img {
      max-width: 280px;
      margin-bottom: 15px;
    }

    &.white {
      color: white;
    }

    &.gray {
      color: $text-color;
    }
  }

  &__content {
    padding: 0;

    &--partner {
      padding: 30px;
      border: 2px solid $dirty-bg-color;
      @include breakpoint(tp) {
        padding: 90px;
      }

      .slideshow__long-arrows {
        position: relative;
        width: auto;

        .icon--arrow-long-left {
          margin-right: 45px;
        }
      }

      .section__header {
        border-bottom: 2px solid $dirty-bg-color;
        flex-direction: column;
        @include breakpoint(tp) {
          flex-direction: row;
        }

        img {
          max-width: 100%;
        }
      }
    }

    &--related {
      border-top: 1px solid $dirty-bg-color;
      padding-top: 90px;

      .red {
        color: $highlight-red-color;
      }

      .slideshow__long-arrows {
        position: relative;
        width: auto;

        .icon--arrow-long-left {
          margin-right: 15px;
          @include breakpoint(tp) {
            margin-right: 45px;
          }
        }
      }
    }
  }
}
/**
 * Font colors
 */
.blue-color {
  color: $highlight-blue-color;
}
/**
 * Background colors
 */
.dark-red-bg-color {
  background-color: $highlight-dark-red-color;
}

.blue-bg-color {
  background-color: $highlight-blue-color;
}

.white-bg-color {
  background-color: $white-bg-color;
}

.dirty-bg-color {
  background-color: $dirty-bg-color;

  &--full-xs {
    @media (max-width: 750px) {
      width: 100%;

      .page-footer__warp,
      .section__wrap {
        padding-left: 0;
        padding-right: 0;
        width: 290px;
        margin: auto;
      }
    }
  }
}

.grey-bg-color {
  background-color: $bg-color;
}
/**
 * Page nav
 */
.page-nav {
  margin-top: 4.5em;
  border-color: $border-color;
  border-style: solid;
  border-width: 1px 0;
  /* social column */
  &__social-col {
    padding-top: 2em;
    clear: both;
    @include breakpoint(tl) {
      padding-top: 0;
      clear: none;
    }
  }
  /* arrows */
  .icon {
    line-height: 46px;

    span {
      display: none;
      @include breakpoint(tp) {
        display: inline;
      }
    }

    &--arrow-left,
    &--arrow-up {
      &::before {
        margin-right: 0.6em;
      }
    }

    &--arrow-right {
      margin-left: 1em;

      &::before {
        margin-left: 0.6em;
        float: right;
      }
    }
  }
  /* social networks */
  .btn-network {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
}
// sticky footer

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Avoid the IE 10-11 `min-height` bug. */
}

.page-wrap {
  // min-height: 100%;
  // margin-bottom: -380px;
  flex: 1 0 auto;
  width: 100%;
}
// .page-wrap:after {
//   content: "";
//   display: block;
//   height: 380px;
// }
/**
 * Page footer
 */
.page-footer {
  flex: none;
  flex-shrink: 0;
  // min-height: 380px;
  color: $light-text-color;

  &__warp {
    padding: 60px;
  }
  /* top bar */
  &__top {
    display: flex;
    flex-wrap: wrap;

    .page-footer__newsletter {
      order: 3;
    }

    .links {
      order: 2;
    }
    @include breakpoint(tl) {
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      .page-footer__newsletter {
        order: initial;
      }

      .links {
        order: initial;
      }
    }
  }
  /* pages menu */
  &__links {
    &:nth-of-type(n+2) {
      @include breakpoint(tp) {
        padding-left: 7%;
      }
    }

    > li {
      .icon {
        color: $highlight-red-color;
        font-weight: bold;

        &:before {
          content: '';
        }

        &:after {
          margin-left: 10px;
        }

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        a {
          text-decoration: underline;
          color: $highlight-red-color;
        }
      }
      text-align: center;
      @include breakpoint(tp) {
        text-align: left;
        margin-bottom: 30px!important;
      }

      &:nth-last-child(1) {
        margin-bottom: 30px !important;
        @include breakpoint(tp) {
          margin-top: 60px;
          margin-bottom: 0 !important;
        }
      }

      &:nth-last-child(2) {
        margin-bottom: 0 !important;
      }
    }

    .brochure {
      display: block;
      margin-top: 20px;
      text-decoration: underline;
      font-weight: 600;
      text-align: center;

      &:hover {
        color: white;
      }

      &--right {
        @include breakpoint(tp) {
          text-align: right !important;
        }
      }
      @include breakpoint(tp) {
        text-align: left;
      }
    }
  }

  &__logos {
    margin-bottom: 60px;
    @include breakpoint(tl) {
      border-left: 2px solid $white-bg-color;
      border-right: 2px solid $white-bg-color;
      padding: 0 20px;
      margin: 0;
    }
    @include breakpoint(dt) {
      padding: 60px;
    }

    .logo-central:first-of-type {
      position: relative;
      height: 100px;
      width: 100px;
      margin: auto;
    }

    .logo {
      position: relative;
      height: 40px;
      margin-top: 5px;

      &:first-of-type {
        img {
          float: left;
          margin-top: 30px;
          @include breakpoint(pl) {
            margin-top: 0;
          }
        }
      }

      &:last-of-type {
        img {
          float: right;
          margin-top: 30px;
          @include breakpoint(pl) {
            margin-top: 0;
          }
        }
      }
    }

    .logos-list {
      .to-left,
      .to-right {
        margin-top: 30px;
        @include breakpoint(pl) {
          margin-top: 0;
        }
      }
    }

    img {
      max-width: 80%;
      max-height: 100%;
      @include breakpoint(pl) {
        max-width: 100%;
      }
    }
  }

  &__networks {
    margin-top: 60px;

    li {
      display: inline-block;

      &:not(:nth-of-type(3n-2)) {
        margin-left: 40px;
      }
    }
    @include breakpoint(pp,tp) {
      margin-bottom: 20px;
      display: block;
      margin: 20px auto;
    }
  }

  &__newsletter {
    display: block;
    @include breakpoint(tp) {
      padding-left: 40px;
    }
    @include breakpoint(tl) {
      // padding-left: 120px;
    }

    form {
      margin-top: 40px;
    }
  }
  /* credits */
  &__bottom {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  /* credits */
  &__credits {
    h6 {
      color: $light-text-color;
      font-size: 16px;
      line-height: 26px;

      span {
        text-transform: uppercase;
      }
    }
  }
}

.text-wrapper {
  padding: 60px 0 30px;

  p {
    padding-bottom: 10px;
  }
}

.call-action {
  @media (max-width: 767px) {
    width: 100%;
    padding-top: 60px;
  }

  &__container {
    background: $highlight-dark-red-color;
    height: 168px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .title;
    color: white;
    @media (max-width: 767px) {
      height: 120px;
    }

    .left {
      padding-left: 30px;
      padding-right: 30px;
      @include breakpoint(tp) {
        padding-left: 60px;
        padding-right: 60px;
      }

      h3 {
        text-transform: none;
      }

      span {
        font-weight: bold;
      }
    }

    .right {
      height: 100%;
      width: 168px;
      background: $highlight-red-color;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 767px) {
        width: 250px;
      }

      span {
        &:after {
          content: '';
        }
      }
    }
  }
}

.not-xs {
  display: none;
}
@include breakpoint(pl) {
  .not-xs {
    display: inline-block;
  }
}

.news-detail {
  .editable-detail {
    margin-top: 45px;
    margin-bottom: 45px;
    @include breakpoint(tp) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
    @include breakpoint(tl) {
      margin-top: 135px;
      margin-bottom: 135px;
    }
  }

  .detail-card__header {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint(tp) {
      margin-top: 60px;
    }
    @include breakpoint(tl) {
      margin-top: 90px;
    }
  }

  .banner-teaser {
    padding: 0;

    &__image {
      width: 100%;
      bottom: 0;
      top: 0;
    }
  }
}
