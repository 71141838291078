/**
 * Breakpoints mixin
 *
 * Author: José Osório
 * Copyright 2016
 */

@mixin breakpoint($min-breakpoint, $max-breakpoint: null) {
    // if min breakpoint is not null...
    @if $min-breakpoint != null {
        // Retrieves the value from the key
        $min-value: map-deep-get($breakpoints, $min-breakpoint, min);

        // If the key exists in the map
        @if $min-value != null {
            // if max breakpoint is not null...
            @if $max-breakpoint != null {
                $max-value: map-deep-get($breakpoints, $max-breakpoint, max);

                // If the key exists in the map
                @if $max-value != null {
                    @media (min-width: $min-value) and (max-width: $max-value){
                        @content;
                    }
                }
            }
            @else
            {
                @media (min-width: $min-value) {
                    @content;
                }
            }

        }
        // If the key doesn't exist in the map
        @else {
            @warn "Unfortunately, no value could be retrieved from `#{$min-breakpoint}`. "
            + "Please make sure it is defined in `$breakpoints` map.";
        }
    }
    // If the min breakpoint is null
    @else {
        @warn "`#{$min-breakpoint}` can not be null. "
        + "Please provide a valid value.";
    }
}
