.banner {
  position: relative;
  // height: calc(100vh - 176px);
  // padding-top: 30px;
  // padding-bottom: 30px;
  @include breakpoint(tp) {
    // height: calc(100vh - 236px);
    // padding-top: 60px;
    // padding-bottom: 60px;
    // height: 550px;
  }

  &__title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 1px;
    font-weight: 300;
    text-align: center;
    color: #414042;
    text-transform: uppercase;
    @include breakpoint(tp) {
      font-size: 38px;
      line-height: 46px;
      text-align: left;
    }
  }

  &--contacts {
    height: 220px;
    border-top: 50px solid #edeff1;
    @include breakpoint(tl) {
      border-top: none;
      ///height: 420px;
    }

    .banner__image {
      width: 100px;
      height: 100px;

      .icon--text {
        margin-left: 0;
      }
    }

    .banner__cell {
      padding-left: 0;
      display: block;
      @include breakpoint(tp) {
        display: table-cell;
      }
    }

    .banner__content {
      padding: 70px 0 40px;
      display: block;
      height: auto;
      @include breakpoint(tp) {
        display: table;
        padding-top: 0;
        height: 220px;
      }
    }
  }

  &__content {
    color: $white-text-color;
    // width: 90%;
    padding-top: 160px;
    height: 220px;
    position: relative;
    @include breakpoint(tp) {
      height: auto;
      padding-top: 0;
      margin-bottom: 90px;
    }
  }

  &__cell {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    @include breakpoint(tp) {
      text-align: left;
      width: 70%;
    }

    h2 {
      overflow: hidden;
      max-height: 200px;
      @include breakpoint(tp) {
        max-height: 150px;
      }
    }
  }

  &__image {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    width: 170px;
    height: 230px;
    top: -100px;
    transform: scale(0.7);
    @include breakpoint(tp) {
      top: -60px;
      transform: scale(0.5);
    }
    @include breakpoint(dt) {
      transform: scale(0.7);
    }

    &--small {
      top: -50px;

      .link-btn-box {
        &:after {
          content: '';
        }
      }
    }
    @include breakpoint(tp) {
      width: 170px;
      height: 230px;
      right: -60px;
      left: auto;
    }
  }

  &--title {
    .banner__content {
      width: 100%;
    }

    .banner__image {
      right: 0;
    }

    .banner__cell {
      padding-left: 0;
    }

    .title {
      color: $highlight-dark-red-color;
    }
  }
}

.banner-teaser {
  width: 100%;
  position: relative;
  padding-top: 30px;
  height: 260px;
  display: flex;
  align-items: flex-end;
  @include breakpoint(tp) {
    height: 580px;
    padding-top: 60px;
    padding-bottom: 60px;
    // height: 550px;
  }
  @include breakpoint(tl) {
    align-items: center;
  }

  &--contacts {
    height: 50vh;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0;
    margin-bottom: 60px;
    @include breakpoint(tp) {
      align-items: center;
      margin-bottom: 120px;
    }
    @include breakpoint(tl) {
      //height: calc(100vh - 116px);
      height: 568px;
    }
    @include breakpoint(xl) {
      height: 568px;
      //height: calc(100vh - 236px);
    }

    .banner-teaser__image {
      bottom: 0;
      left: 0;
      @include breakpoint(tp) {
        width: calc(100% - 150px);
      }
      @include breakpoint(dt) {
        width: calc(100% - 210px);
      }
      @include breakpoint(xxl) {
        //width: 100%;
      }
    }

    .banner-teaser__header {
      display: none;
      @include breakpoint(tp) {
        display: table;
      }
    }

    .btn {
      max-width: 80px;
      float: right;
      @include breakpoint(tp) {
        float: none;
        max-width: 280px;
      }
    }
  }

  &__caption-wrap {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: calc(100vh - 176px);
    @include breakpoint(tp) {
      height: calc(100vh - 236px);
    }

    .banner-teaser__caption {
      padding-top: 0;
      padding-bottom: 0;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: flex-end;
      -ms-flex-item-align: flex-end;
      align-self: flex-end;
      @include breakpoint(tl) {
        align-self: center;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
      }
    }
  }
  /* image */
  &__image {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    @extend .bg-cover;
    transition: top 0.8s;
    backface-visibility: hidden;
    width: calc(100%);
    @include breakpoint(tp) {
      bottom: 60px;
      top: 60px;
    }
    @include breakpoint(tl) {
      display: initial;
      width: calc(100% - 280px);
      //width: calc((100% - (100% - 960px) / 2) - 200px);
    }
    @include breakpoint(dt) {
      //width: calc((100% - (100% - 1200px) / 2) - 180px);
    }
    @include breakpoint(xxl) {
      //width: calc((100% - (100% - 1200px) / 2));
    }
  }

  &__caption {
    position: relative;
    width: 100%;
    @include breakpoint(tp) {
      width: 350px;
      padding-top: 0 !important;
    }
    @include breakpoint(dt) {
      width: 400px;
    }
    @include breakpoint(xxl) {
      //left: -180px;
    }
  }

  &__header {
    min-height: 140px;
    background-color: $dirty-bg-color;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    display: none;
    @include breakpoint(tp) {
      display: table;
    }
    @include breakpoint(dt) {
      min-height: 280px;
      padding: 68px 56px 68px 32px;
    }

    &--blue {
      span {
        color: $highlight-blue-color !important;
      }
    }

    span {
      //color: $highlight-red-color;
      text-transform: uppercase;
    }

    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 28px;
      line-height: 38px;
      min-height: calc(38px * 1);
      max-height: calc(38px * 2);
      //text-transform: none;
      @include breakpoint(dt) {
        font-size: 38px;
        line-height: 48px;
        min-height: calc(48px * 1);
        max-height: calc(48px * 2);
      }
    }
  }

  &__wrapper {
    display: table-cell;
    vertical-align: middle;

    p {
      //text-transform: lowercase;
    }
  }

  &--top {}
}
