/**
 * Custom inputs
 *
 * Author: José Osório
 * Copyright 2016
 */

/* select */
.custom_select{
	height: 38px;
	padding: 0 15px;
	color: inherit;
	text-align: left;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;

	@include breakpoint(pl){
		height: 41px;
		line-height: 41px;
	}
	@include breakpoint(tp){
		height: 44px;
		line-height: 44px;
	}
	@include breakpoint(tl){
		height: 47px;
		line-height: 47px;
	}
	@include breakpoint(dt){
		height: 50px;
		line-height: 50px;
	}

	/* displayer */
	div{
		height: 100%;
		margin-right: 20px;
		white-space: nowrap;
		position: relative;
		overflow: hidden;
	}

	/* button */
	span{
		width: 0;
		height: 0;
		margin: auto;
		border-top: 6px solid #666;
		border-right: 6px solid transparent;
		border-left: 6px solid transparent;
		display: block;
		position: absolute;
		top: 0;
		right: 15px;
		bottom: 0
	}

	/* input */
	select{
		width: 100%;
		height: 100%;
		margin: auto;
		padding: 0;
		border: none;
		box-sizing: border-box;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;

		option{
			color: inherit;
			font: inherit;
		}
	}

	/* when the select box is disabled */
	&.disabled{
		opacity: .7;
	}

	/* when the select box is not disabled */
	&:not(.disabled):hover{
		color: #666;
		border-color: #666;

		span{
			opacity: .8;
		}
	}
}


/* checkbox */
.custom_checkbox{
	width: 14px;
	height: 14px;
	margin-bottom: -4px;
	background-color: #fff;
	border: 1px solid #ccc;
	display: inline-block;
	position: relative;

	> span{
		width: 6px;
		height: 6px;
		margin: auto;
		background-color: transparent;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		&.current{
			background-color:#c1c1c1;
		}
	}

	input{
		width: 14px;
		height: 14px;
		opacity: 0;
		margin: 0!important;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		display: block;
	}

	&.disabled{
		border-style: dotted;
		opacity: .7;
	}
	&.disabled,
	&.disabled input{
		cursor: default;
	}
	&.disabled + label{
		opacity: .7;
		cursor: default;
	}
}

/* radio button */
.custom_radio{
	width: 14px;
	height: 14px;
	margin-bottom: -4px;
	border: 1px solid #c5c5c5;
	border-radius: 50%;
	box-sizing: border-box;
	display: inline-block;
	position: relative;

	> span{
		width: 8px;
		height: 8px;
		margin: auto;
		background-color: transparent;
		border-radius: 50%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left:0;

		&.current{
			background-color: #c1c1c1;
		}
	}

	input{
		width: 100%;
		height:100%;
		opacity: 0;
		margin: 0px;
		padding: 0px;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
	/* disabled */
	&.disabled{
		border-style: dotted;
		opacity: .7;
	}
	&.disabled,
	&.disabled input{
		cursor:default;
	}
	&.disabled + label{
		opacity: .7;
		cursor:default;
	}
}
