/**
 * Styles for building great Web Applications!
 *
 * Author: José Osório
 * Copyright 2016
 */

/* base styles */
@import "base/reset.scss";
@import "base/config.scss";

/* function */
@import "functions/lists.scss";

/* mixinx */
@import "mixins/breakpoint.scss";
@import "mixins/grid.scss";
@import "mixins/font.scss";
@import "mixins/text.scss";
@import "mixins/forms.scss";
@import "mixins/to.scss";
@import "mixins/shapes.scss";

/* layout */
@import "layout/typography.scss";
@import "layout/grid.scss";
@import "layout/layout.scss";

/* components */
// @import "components/navbar.scss";
@import "components/menus.scss";
@import "components/icons.scss";
@import "components/inputs.scss";
@import "components/form.scss";
@import "components/banner.scss";
@import "components/slideshow.scss";
@import "components/accordion.scss";
