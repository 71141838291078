/**
 * Banner component
 *
 * Author: José Osório
 * Copyright 2016
 */

.banner{
	height:	450px;
	position: relative;

	@include breakpoint(dt){
		height: 500px;
	}

	&--full{
		height: 100vh;
	}

	&__content{
		width: 100%;
		height: 100%;
		display: table;
		@extend .abs-center;
	}

	&__cell{
		padding-right: 10%;
		padding-left: 10%;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
	}
}
