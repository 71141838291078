/**
 * Grid styles
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Row
 */
.row::after{
	content: '';
	clear: both;
	display: block;
}

/**
 *  Columns
 */
 @include make-grid();
 @include make-grid(pl);
 @include make-grid(tp);
 @include make-grid(tl);
 @include make-grid(dt);
