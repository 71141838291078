/**
 * List function
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Checks if an element exist in a list
 */
@function in-list($var, $list){
    $_out: false;
    @each $item in $list { @if $item == $var { $_out: true; } }
    @return $_out;
}

/**
 * Gets a key in a nested map
 */
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}
