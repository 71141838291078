/**
 * Slideshow component
 *
 * Author: José Osório
 * Copyright 2016
 */

.slideshow {
    position: relative;

    &--full {
        width: 100%;
        height: 100%;
        @extend .abs-center;
    }

    /* track */
    &__track {
        height: 100%;
    }

    &__slide {
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
    }

    /* dots */
    &__dots{
        margin-top: 20px;
        text-align: center;

        &--abs-bottom{
            margin: auto;
            position: absolute;
            right: 0;
            bottom: 40px;
            left: 0;
        }

        li{
            display: inline-block;

            button{
                width: 24px;
                height: 24px;
                text-indent: 2000em;
                border-width: 2px;
                border-style: solid;
                border-color: $border-color;
                border-radius: 50%;
                overflow: hidden;
                transition: background-color .4s;
            }

            &:not(:last-child) button{
                margin-right: 4px;
            }
            &:not(:first-child) button{
                margin-left: 4px;
            }

            /* hover / active */
            &:hover,
            &.slick-active{
                button{
                    background-color: $border-color;
                }
            }
        }
    }

    /**
    * Slick
    */
    .slick-list,
    .slick-track {
        height: 100%;
    }
}
