.map-card {
  &__wrapper {
    &:nth-of-type(n-1) {
      @include breakpoint(tp) {
        padding-right: 10px;
      }

      @include breakpoint(tl) {
        padding-right: 15px;
      }
    }

    &:nth-of-type(n+2) {
      margin-top: 50px;
      @include breakpoint(tp) {
        margin-top: 0;
        padding-right: 0px;
        padding-left: 10px;
      }
      @include breakpoint(tl) {
        padding-left: 15px;
      }

      .big-card__box {
        background-color: $highlight-blue-color;
      }

      .map-card__icon {
        background-color: $highlight-dark-blue-color;
      }
    }
  }

  &__map {
    height: 300px;
  }

  &__icon {
    width: 80px;
    line-height: 80px;
    text-align: center;
    background-color: #eb0029;
    font-size: 28px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    &:after {
      content: '';
    }
    @include breakpoint(dt) {
      line-height: 100px;
      width: 100px;
    }
  }

  &__header {
    .big-card__title {
      height: 50px;
      overflow: hidden;
    }

    .big-card__caption {
      width: 210px;
      @include breakpoint(tp) {
        width: 170px;
      }
    }
    @include breakpoint(tp) {
      float: right;
    }
  }

  &__address {
    width: 100%;
    //margin-top: 30px;
    border: 1px solid $dirty-bg-color;

    .wrap {
      padding: 30px;
    }
    @include breakpoint(pl) {
      width: 100%;
    }
    @include breakpoint(tp) {
      float: right;
      width: 100%;
      margin-top: 0;
    }
    @include breakpoint(tl) {
      width: 100%;
      margin-top: 0;
    }
  }
}

.contacts-form__header {
  padding-bottom: 30px;
  @include breakpoint(tp) {
    padding-right: 30px;
    padding-bottom: 0;
  }
  @include breakpoint(dt) {
    padding-right: 90px;
  }

  h3 {
    text-transform: lowercase;
    color: $highlight-red-color;
  }
}

.editable-map {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $highlight-red-color;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;

    &:nth-child(2) {
      color: $dark-text-color;
      text-transform: none;
      margin-bottom: 30px;
      font-weight: bold;
    }
  }

  p {
    margin-bottom: 10px;
    color: $light-text-color;
    font-weight: lighter;

    &:first-of-type {
      max-height: 62px;
      min-height: 62px;
      overflow: hidden;
    }
  }
}
