/**
 * Button
 */
.btn {
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: block;
  height: 80px;
  max-width: 270px;
  overflow: hidden;
  text-align: left;
  color: $white-text-color;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: $primary-font;
  font-weight: $font-weight-regular;
  text-transform: lowercase;
  background-color: $highlight-dark-red-color;
  padding-right: 10px;
  padding-left: 20px;
  border: none;
  box-sizing: border-box;
  transition: background-color 0.5s;
  // center
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;

  &--ml-auto{
    margin-left: auto;
  }
  @include breakpoint(dt) {
    height: 90px;
  }

  &:focus,
  &:hover {
    // background-color: $highlight-red-color;
  }

  a {
    max-width: 160px;
    max-height: 50px;
    overflow: hidden;
    display: block;
  }
  @include breakpoint(tp) {
    width: 280px;
    max-width: initial;
  }
  /*   only icon */
  &--full-width {
    width: auto;
    padding-right: 100px;

    a {
      max-width: initial;
    }
  }
  /*   only icon */
  &--more {
    width: 50px;
    height: 50px;
    background-color: $highlight-red-color;

    &:hover {
      background-color: $light-bg-color;
    }
  }
  /* center */
  &--center {
    margin: 30px auto;
    text-align: center;
    display: block;
  }
  /* responsive */
  &--responsive {
    border: none;

    &:focus,
    &:hover {
      color: $text-color;
    }
  }

  &--blue {
    background-color: $highlight-blue-color;

    &:hover {
      //background-color: $highlight-dark-blue-color;
    }

    &:after {
      //background-color: $highlight-dark-blue-color !important;
    }
  }

  &:hover {
    &::after {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: bottom left;
      transform-origin: bottom left;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    //background-color: #eb0029;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    transition: -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
}
/* icon in the box */
.btn-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 80px;
  text-align: center;
  transition: 0.3s background-color, color;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @include breakpoint(tp) {
    width: 80px;
    height: 80px;
  }
  @include breakpoint(dt) {
    height: 90px;
    width: 90px;
  }

  &::after,
  &::before {
    line-height: 100px;
    font-size: 28px;
    font-family: $icon-font;
    position: absolute;
    transition: transform 0.2s;
  }

  &:after {
    transform: translateY(100px);
  }

  &--small {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  &--white {
    color: $white-text-color;
    background-color: $highlight-red-color;

    &:hover {
      &::after {
        transform: translateY(0px);
      }

      &::before {
        transform: translateY(-100px);
      }
    }
  }

  &--grey {
    color: $highlight-red-color;
    background-color: $dirty-bg-color;

    &:hover {
      &::after {
        transform: translateY(0px);
      }

      &::before {
        transform: translateY(-100px);
      }
    }
  }

  &--red {
    color: $highlight-red-color;
    background-color: $white-bg-color;

    &:hover {
      &::after {
        transform: translateY(0px);

      }

      &::before {
        transform: translateY(-100px);
      }
    }
  }

  &--blue {
    background-color: $highlight-dark-blue-color;

    &:hover {
      &::after {
        transform: translateY(0px);
      }

      &::before {
        transform: translateY(-100px);
      }
    }
  }
}
/**
 * Links
 */
.link-btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-family: $primary-font;
  font-weight: $font-weight-regular;
  font-size: 16px;
  line-height: 25px;
  color: $highlight-red-color;
  transition-property: color;
  transition-duration: 0.5s;

  &:hover {
    color: $light-text-color;
  }

  .icon {
    line-height: 25px;
    margin-left: 10px;

    &:after {
      content: '';
    }
  }

  &--mr {
    .icon {
      margin-left: 30px;
      @include breakpoint(tl) {
        margin-left: 60px;
      }
    }
  }
}

.link-btn-box {
  max-width: 280px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-family: $primary-font;
  font-weight: $font-weight-regular;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 100px;
  background-color: $highlight-dark-red-color;
  color: $white-text-color;
  padding-left: 15px;
  box-sizing: border-box;
  transition: 0.3s background-color;
  @include breakpoint(tp) {
    max-width: initial;
    padding-left: 30px;
    font-size: 18px;
  }

  &:hover {
    background-color: $highlight-red-color;
  }

  &--blue {
    background-color: $highlight-blue-color;

    &:hover {
      background-color: $highlight-dark-blue-color;
    }

    .link-btn-box--icon {
      background-color: $highlight-dark-blue-color;

      &:hover {
        background-color: $highlight-blue-color;
      }
    }
  }

  &--icon {
    width: 100px;
    text-align: center;
    background-color: $highlight-red-color;
    font-size: 28px;
    font-family: $icon-font;
    padding: 0;
    margin-left: 10px;
    @include breakpoint(tp) {
      margin-left: 30px;
    }

    &:after {
      //content: '';
    }

    &--small {
      font-size: 20px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }

    &:hover {
      background-color: $highlight-dark-red-color;
    }
  }
}
/**
 * Scroll button
 */
.scroll-btn {
  position: relative;
  color: $highlight-color;
  font-size: 26px;
  box-sizing: border-box;
  cursor: pointer;
  /* scroll down */
  &--down {
    height: 50px;
    width: 50px;
    border: 2px solid $white-border-color;
    color: $text-color;
    font-size: 35px;

    &::before {
      @include icon(map-get($icons, arrow-down));
    }

    &:focus,
    &:hover {
      border-color: $border-color;
    }
  }
}
/**
 * Arrow buttons
 */
.btn-arrow {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  font-size: 22px;
  display: inline-block;
  color: $white-text-color;
  transition: all 0.5s, color;
  @include breakpoint(tl) {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  @include breakpoint(dt) {
    width: 90px;
    height: 90px;
    line-height: 90px;
  }

  &:hover {
    background-color: $highlight-dark-red-color;
  }

  &--box {
    background-color: $white-bg-color;
    border: 1px solid $dirty-border-color;
    color: $highlight-red-color;

    &:hover {
      background-color: $white-bg-color;
      border-color: $highlight-dark-red-color;
    }
  }
  /* left */
  &--left {
    float: left;

    &::before {
      @include icon(map-get($icons, arrow-long-left));
    }
  }
  /* right */
  &--right {
    float: right;

    &::before {
      @include icon(map-get($icons, arrow-long-right));
    }
  }
}
/**
 * Networks
 */
.btn-network {
  @include make-circle(50);
  font-size: 18px;
  line-height: 50px;
  background-color: $white-bg-color;
  //border: 2px solid $light-border-color;
  color: $text-color;
  box-sizing: border-box;
  transition-property: color, background-color;
  transition-duration: 0.5s;

  span {
    display: none;
  }

  &--facebook {
    &::before {
      @include icon(map-get($icons, facebook));
    }
  }

  &--linkedin {
    &::before {
      @include icon(map-get($icons, linkedin));
    }
  }

  &--youtube {
    &::before {
      @include icon(map-get($icons, youtube));
    }
  }
  /* hover */
  &:hover {
    color: $highlight-red-color;
    //border: 2px solid $highlight-red-color;
  }
}
// GO BACK BTN

.nav {
  a {
    color: $light-text-color;
    padding-top: 0;
    line-height: 30px;
    display: block;
    width: 50px;
    text-align: center;
    background-color: $dirty-bg-color;

    &:hover {
      color: $highlight-dark-red-color;
    }
    @include breakpoint(tp) {
      padding-top: 10px;
      line-height: 50px;
      display: initial;
      // width:auto;
      background-color: none;
    }

    .icon {
      font-size: 20px;
      @include breakpoint(tp) {
        line-height: initial;
        font-size: inherit;
      }
    }
  }

  span {
    padding-left: 5px;
    display: none;
    @include breakpoint(tp) {
      display: initial;
    }
  }
}

.breadcrumbs {
  li {
    display: inline-block;
    line-height: 30px;
    @include breakpoint(tp) {
      line-height: 50px;
    }

    &:nth-of-type(n+2) {
      margin-left: 5px;

      &::before {
        content: '/';
      }
    }

    &.active,
    &:hover {
      a {
        color: $black-text-color;
      }
    }

    a {
      color: $light-text-color;
    }
  }
}
