/**
 * Icons components
 */

.icon {
	&::before,&::after {
		font-family: $icon-font;
		font-style: normal;
	}


	@each $modifier, $code in $icons {
		&--#{$modifier}::before {
			content: $code;
		}
		&--#{$modifier}::after {
			content: $code;
		}
	}
}
