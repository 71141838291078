/**
 * Colors
 */
$colors: (
    'black'             : #1A1A1A,
    'dark-grey'         : #2D2D2D,
    'grey'              : #414042,
    'light-grey'        : #8C8C8C,
    'dark-red'          : #cf102d,
    'red'               : #eb0029,
    'blue'              : #0093c9,
    'dark-blue'         : #0076a9,
    'dirty-white'       : #edeff1,
    'white'       : #fff,
);
/**
 * Color applications
 */
 $black-text-color               : map-get($colors, 'black');
 $dark-text-color                : map-get($colors, 'dark-grey');
 $text-color                     : map-get($colors, 'grey');
 $light-text-color               : map-get($colors, 'light-grey');
 $dirty-text-color               : map-get($colors, 'dirty-white');
 $white-text-color               : map-get($colors, 'white');

$highlight-dark-red-color        : map-get($colors, 'dark-red');
$highlight-red-color             : map-get($colors, 'red');
$highlight-dark-blue-color       : map-get($colors, 'dark-blue');
$highlight-blue-color            : map-get($colors, 'blue');

/* backgrounds */
$black-bg-color               : map-get($colors, 'black');
$dark-bg-color                : map-get($colors, 'dark-grey');
$bg-color                     : map-get($colors, 'grey');
$light-bg-color                : map-get($colors, 'light-grey');
$dirty-bg-color               : map-get($colors, 'dirty-white');
$white-bg-color               : map-get($colors, 'white');

/* borders */
$black-border-color             : map-get($colors, 'white');
$dark-border-color              : map-get($colors, 'dark-grey');
$border-color                   : map-get($colors, 'grey');
$light-border-color             : map-get($colors, 'light-grey');
$dirty-border-color             : map-get($colors, 'dirty-white');
$white-border-color             : map-get($colors, 'white');


/**
 * Fonts
 */
 $primary-font      : 'Source Sans Pro', sans-serif;
 $icon-font         : 'Material-Design-Iconic-Font', sans-serif;

$font-weight-light    : 300;
$font-weight-regular  : 400;

 /**
  * Icons
  */
 $icons: (
    /* miscelaneous */
    menu            : '\f197',
    phone           : '\F2BE',
    world           : '\F173',
    search          : '\F1C3',
    user            : '\F205',
    plus            : '\F278',
    pdf             : '\F14D',
    pin             : '\F1AB',
    link            : '\F18E',
    text            : '\F263',
    send            : '\F194',
    close            : '\f136',


    /* arrows */
    arrow-up        : '\F2F8',
    arrow-down      : '\F2F2',
    arrow-right     : '\f301',
    arrow-left      : '\F2F4',
    arrow-long-right: '\f301',
    arrow-long-left : '\f2ff',

    /* social media */
    linkedin        : '\F3F8',
    facebook        : '\F343',
    youtube         : '\F408',
 );
