/**
 * Font mixins
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Icons building
 */
@mixin icon($code){
    font-family: $icon-font;
    content: $code;
}
