/**
 * Forms mixins
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Placeholder mixin
 */
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
