/**
 * Headings
 */
.title {
	font-family: $primary-font;
	font-size: 30px;
	line-height: 40px;
	letter-spacing: 1px;
	font-weight: $font-weight-light;
	text-transform: uppercase;
	@include breakpoint(tl) {
		letter-spacing: 1.4px;
		font-size: 32px;
		line-height: 40px;
	}
	@include breakpoint(dt) {
		letter-spacing: 1.6px;
		//font-size: 36px;
		//line-height: 44px;
		font-size: 48px;
		line-height: 58px;
	}
}

h3 {
	font-family: $primary-font;
	font-size: 25px;
	line-height: 30px;
	letter-spacing: 1px;
	font-weight: $font-weight-light;
	text-transform: uppercase;
	@include breakpoint(tp) {
		font-size: 30px;
		line-height: 34px;
		letter-spacing: 1.4px;
	}
	@include breakpoint(tl) {
		font-size: 35px;
		line-height: 40px;
		letter-spacing: 1.4px;
	}
}

h4 {
	font-family: $primary-font;
	font-size: 16px;
	font-weight: $font-weight-regular;
	text-transform: uppercase;
}

p {
	font-family: $primary-font;
	font-size: 18px;
	letter-spacing: 0.9px;
	line-height: 28px;
	font-weight: $font-weight-regular;
}

h6 {
	font-family: $primary-font;
	font-size: 13px;
	letter-spacing: 0.5px;
	font-weight: $font-weight-regular;
}

.link {
	font-family: $primary-font;
	font-size: 18px;
	letter-spacing: 0.5px;
	line-height: 24px;
	font-weight: $font-weight-regular;
	font-style: italic;

	&:hover {
		color: $highlight-red-color;
	}

	&--red {
		color: $highlight-red-color;
	}
}
/**
 * Editable contents
 */
.editable {
	p + h4 {
		margin-top: 1.5em;
	}

	img {
		max-width: 100% !important;
		height: auto !important;
		margin: 2.5em auto !important;
		display: block !important;
		@include breakpoint(tl) {
			max-width: 80% !important;
		}
	}
}

.editable-detail {
	
	@include breakpoint(tp) {
		padding: 0 50px;
	}
	@include breakpoint(tl) {
		padding: 0 100px;
	}

	p,
	strong {
		font-size: 24px;
		line-height: 32px;
		font-weight: lighter;
	}

	p {
		color: $text-color;
	}
}
