/**
 * Form component
 *
 * Author: José Osório
 * Copyright 2016
 */

/* elements */
[onclick], [type="submit"], [type="checkbox"], [type="radio"], button{
    cursor: pointer;
}

.form{
    fieldset{
        margin: 0;
        padding: 0;
        border: none;

        legend{
            padding: 0;
            display: block;
        }
    }

    label{
        cursor: pointer;
    }
    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        background-color: $bg-color;
        box-sizing: border-box;
        display: block;
        -webkit-appearance: none;
    }
    textarea{
        height: 150px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    select{
        height: 38px;

        @include breakpoint(pl){
            height: 41px;
        }
        @include breakpoint(tp){
            height: 44px;
        }
        @include breakpoint(tl){
            height: 47px;
        }
        @include breakpoint(dt){
            height: 50px;
        }
    }

    /* placeholders */
    @include placeholder{
        color: inherit;
        opacity: 1;
    }

    /* form header */
    &__header{
        margin-bottom: 15px;
    }

    /* form group */
    &__group{
        margin-bottom: 12px;
        overflow: hidden;

        @include breakpoint(tp){
            margin-bottom: 20px;
        }

        /* form grid */
        .row{
            margin: 0 -6px;

            /* columns */
            [class*="col-"]{
                padding: 0 6px;
            }

            @include breakpoint (tp){
                margin: 0 -10px;

                /* columns */
                [class*="col-"]{
                    padding: 0 10px;
                }
            }
        }
    }

    /* form footer */
    &__footer{
        margin-top: 30px;
        text-align: center;

        p{
        	margin: 0;
        }

        @include breakpoint (tp){
            text-align: left;
        }
    }
}
