.nav {
  .icon {
    &:after {
      content: '';
    }
  }

  a {
    background: transparent !important;
  }
}

.navbar {
  .icon {
    &:after {
      content: '';
    }
  }
  /* logo */
  &__logo {
    padding-top: 30px;
    padding-bottom: 4px;
    vertical-align: top;
  }

  &__top {
    height: 30px;
    //border-bottom: 1px solid $dirty-border-color;
  }

  &__bottom {
    height: 75px;
    // padding: 5px 0;
    border-bottom: 2px solid $dirty-bg-color;
    box-sizing: border-box;
    position: relative;
    @include breakpoint(tl) {
      height: 85px;
      // padding-top: 25px;
    }
  }
  /* menu */
  &__menu {
    display: none;
    position: relative;
    z-index: 3;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    @include breakpoint(tl) {
      height: 85px;
      //padding-top: 25px;
    }
    @include breakpoint(tp) {
      // padding-top:10px;
    }
    @include breakpoint(tl) {
      display: inline;
    }

    > ul {
      //height: 50px;
      height: 100%;
      position: relative;
    }

    li {
      display: inline-block;
      float: none;
      height: 100%;
      position: relative;
      @include breakpoint(tp) {
        margin-right: 15px;
        margin-left: 15px;
      }

      a {
        //padding: 30px 0;
        font-family: $primary-font;
        font-weight: $font-weight-regular;
        font-size: 14px;
        white-space: nowrap;
        color: $text-color;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        height: calc(100% - 3px);

        &:hover {
          color: $highlight-red-color;
        }

        &.active {
          border-bottom: 2px solid $highlight-red-color;
          color: $highlight-red-color;
        }
      }
    }

    &--active {
      display: table;
      background-color: $white-bg-color;
      position: absolute;
      height: auto;
      width: 100%;
      top: 100%;
      left: 0;
      right: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      z-index: 20;
      box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
      @include breakpoint(tp) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
      @include breakpoint(dt) {
        padding-top: 180px;
        padding-bottom: 180px;
      }

      ul {
        height: auto;
        display: table-cell;
        vertical-align: middle;
        padding: 15px;
      }

      li {
        display: block;
        text-align: center;
        @include breakpoint(tp) {
          padding: 15px 0;
        }

        a {
          padding: 15px 0;
          font-size: 16px;
        }
      }
    }
  }
  /**
     * Button Menu
     */
  &__menu-toggler {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    z-index: 10;

    &::before {
      font-size: 30px;
      line-height: 85px;
    }

    &:hover {
      color: $highlight-red-color;
    }
    @include breakpoint(tl) {
      display: none;
    }

    &--close {
      color: $highlight-red-color;
    }
  }
}

.submenu {
  display: block;

  &__content {
    line-height: 30px;
    font-size: 14px;
    color: $light-text-color;

    .icon::before {
      margin-right: 10px;
      font-size: 20px;
      @include breakpoint(tp) {
        font-size: 14px;
      }
    }

    span {
      display: none;
      @include breakpoint(tp) {
        display: inherit;
      }
    }

    > ul {
      position: relative;
    }

    li {
      display: inline-block;
      position: relative;
      line-height: 30px;
      color: $light-text-color;
      @include breakpoint(tp) {
        padding: 0 20px;
      }

      &:nth-of-type(n+2) {
        &:before {
          content: '/';
          position: absolute;
          left: 0;
          color: $highlight-red-color;
        }
      }

      &:hover {
        color: $highlight-red-color;
      }
    }
  }

  &__links {
    display: none !important;
    @include breakpoint(tl) {
      display: block !important;
    }

    &:after {
      content: '/';
      position: absolute;
      right: 0;
      top: 0;
      color: $highlight-red-color;
    }
  }
  //responsive
  &--active {
    .submenu__links {
      background-color: $dirty-bg-color;
      display: block;
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 4;

      li {
        display: block;
        text-align: center;
        padding: 15px 0;

        &::before {
          display: none;
        }

        a {
          padding: 15px 0;
        }
      }
    }
  }
}
/**
* Langs Area
*/
.language {
  position: relative;
  cursor: pointer;

  &:hover {
    color: $highlight-red-color;
  }

  ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin: auto;
    padding: 10px;
    background-color: $dirty-bg-color;
    transition: all 0.1s;
    z-index: 1;

    li {
      display: block;
      padding: 0 10px;
      @include breakpoint(tp) {
        display: inline-block;
      }

      &::before {
        display: none;
        @include breakpoint(tp) {
          display: block;
        }
      }

      &:hover {
        a {
          color: $highlight-red-color;
          text-decoration: underline;
        }
      }
    }
  }

  &--active {
    ul {
      display: block;
    }
  }
}
/**
* Search
*/
.search {
  padding: 0 0 0 20px !important;

  .icon--search {
    margin-right: 0;
  }

  &__button {
    color: $highlight-red-color;
    font-size: 27px;
    line-height: 20px;

    &:hover {
      color: $dark-text-color;
    }
  }

  .search__form {
    display: none;
    width: 150px;
    @include breakpoint(tp) {
      width: initial;
      position: relative;
      top: initial;
    }
  }

  &--active {
    .search__form {
      display: block!important;
    }

    .search__button {
      display: none;
    }
  }
}

.app-logo {
  width: 180px;
  height: 50px;
  position: relative;
  display: inline-block;

  a {
    @extend .abs-center;
    width: 100%;
    height: inherit;
    line-height: 50px;
    text-indent: 2000em;
    background: url("/front/images/logo.svg") no-repeat center /contain;
    overflow: hidden;
  }
}

.down-tl {
  display: block !important;
  @include breakpoint(tp) {
    display: none !important;
  }
}
