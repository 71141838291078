
.newsletter{
    position: relative;
    //background-color: transparent;
    box-sizing: border-box;
    //border: 1px solid $dark-border-color;
    color: $light-text-color;
    background: $white-bg-color;
    margin-top: 60px;

    /*@include breakpoint(pp, pl){
        margin: auto;
    }*/

    input{
        width: 100%;
        height: 60px;
        padding: 0 50px 0 10px;
        box-sizing: border-box;

        &::placeholder{
        font-style: italic;
        }
    }

    &__btn{
        font-size: 25px;
        padding-left: 10px;
        position: absolute;
        top:0;
        bottom: 0;
        margin: auto;
        right: 0px;
        width: 60px;
        color:$white-text-color;
        background-color:$light-bg-color;
        &:hover{
            //color: $highlight-red-color;
        }

        &:after{
          content: '';
        }
    }
}

.search__form{
    @include breakpoint(pp,tp){
        width: 280px;
    }

    @include placeholder {
        color: $text-color;
        font-size: 15px;
        font-style: italic;
    }

    input{
        height: 100%;
        width: 80%;
        display: inline-block;
        border-bottom: 1px solid $dark-border-color;
        font-weight: $font-weight-regular;
        color: $text-color;
        padding-bottom: 3px;
        &:focus{
            border-color: $border-color;
        }
        @include breakpoint(tl){
            width: 120px;
        }
    }

    button{
        line-height: 20px;
        display: inline-block;
        color: $highlight-red-color;
        font-size: 27px;

        &:hover{
            color: $dark-text-color;
        }
    }

}

label{
    cursor: pointer;
}

.form{
    padding-top: 40px;

    @include breakpoint(tp){
        padding-top: 0;
        padding-left: 40px;
    }

    &--white{
        padding-left:0;

        input[type=password],
        input[type=email],
        input[type=text],
        input[type=file]{
            background-color: $white-bg-color !important;
            color: $light-text-color !important;
        }
    }

    &__header{
      @include breakpoint(tp){
        padding-right: 50px;
      }
      h2{
        text-transform: lowercase;
        color: $highlight-dark-red-color;
        font-weight: $font-weight-light;
        font-size: 35px;
      }
    }
    &__footer{
        margin-top: 15px;
    }

    input[type=password],
    input[type=email],
    input[type=text],
    input[type=file],
    textarea{
        margin-bottom: 5px;
        background-color: $dirty-bg-color;
        color: $text-color;
        border:1px solid $dirty-bg-color;
    }

    input{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        height: 90px !important;
        margin-bottom: 30px !important;
    }

    .col-tp-6,
    .col-12,.col-6{
      @include breakpoint(tp) {
        padding-right: 15px;
        padding-left: 15px;
      }
        box-sizing: border-box;


    }

    input:focus, textarea:focus {
        color:$highlight-red-color;
    }

    .row{
        margin-bottom: 40px;
    }
    /* op results */
    .op_result{
        p{
            margin-bottom: 1.5em;
        }
    }

}
