.user-area{
    &__col{

        @include breakpoint(tp){

            &:nth-of-type(2n-1){
                padding-right: 20px;
            }
            &:nth-of-type(2n){
                padding-left: 20px;
            }
        }
    }

    &__info{
            h4{
                padding-bottom: 20px;
            }
    }
    &__select{
        margin-top: 20px;

        @include breakpoint(tp){
          margin-top: 0;
            padding-left:50px;
        }
        select{
          height: 50px;
            width: 100%;
            cursor:pointer;
            background-color: $white-text-color;
            color: #b7b7b7;
            font: $primary-font !important;
            font-size: 15px;
            text-transform: uppercase;
            padding: 10px 30px 10px 20px;
            outline:none;
            transition: color .3s 0s;
            &:hover,
            &:focus{
              color: #eb0029;
            }
        }
    }

    &__docs{
        width: 100%;
        padding-top:40px;
    }

    &__form{
        margin-top: 30px;

        @include breakpoint(tp){
            width: 60%;
        }
    }

}
