/**
 * Layout styles
 *
 * Author: José Osório
 * Copyright 2016
 */

/**
 * Body
 */
body{
	line-height: 1.5;
	font-size: 14px;
	font-family: $primary-font;
	color: $text-color;

	@include breakpoint(tp){
		font-size: 15px;
	}
	@include breakpoint(dt){
		font-size: 16px;
	}
}

/**
 * Container
 */
.container{
	width: map-get($container-widths, pp);
	margin-right: auto;
	margin-left: auto;

	@include breakpoint(pl){
		width: map-get($container-widths, pl);
	}
	@include breakpoint(tp){
		width: map-get($container-widths, tp);
	}
	@include breakpoint(tl){
		width: map-get($container-widths, tl);
	}
	@include breakpoint(dt){
		width: map-get($container-widths, dt);
	}

	&:after{
		content: '';
		display: block;
		clear: both;
	}
}

/**
 * Backgrounds
 */
.bg-cover{
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}


/**
 * Section
 */
.section{
	padding-top: 4em;
	padding-bottom: 3em;

	/* section header */
	&__header{
		padding-bottom: 3em;
	}

	/* section content */
	&__content{
		padding-bottom: 3em;
	}

	/* section footer */
	&__footer{
		padding-top: 22px;
		padding-bottom: 22px;

		@include breakpoint(pl){
			padding-top: 24px;
			padding-bottom: 24px;
		}
		@include breakpoint(tp){
			padding-top: 26px;
			padding-bottom: 26px;
		}
		@include breakpoint(tl){
			padding-top: 28px;
			padding-bottom: 28px;
		}
		@include breakpoint(dt){
			padding-top: 30px;
			padding-bottom: 30px;
		}

		p:last-of-type{
			margin-bottom: 0;
		}
	}
}


/**
 *	Spacings
 */
.auto-margin{
	margin-right: auto !important;
	margin-left: auto !important;
	float: none !important;
}
.no-margin{
	margin: 0 !important;
}
.no-margin-top{
	margin-top: 0 !important;
}
.no-margin-right{
	margin-right: 0 !important;
}
.no-margin-bottom{
	margin-bottom: 0 !important;
}
.no-margin-left{
	margin-left: 0 !important;
}
.no-padding{
   padding: 0 !important;
}
.no-padding-top{
   padding-top: 0 !important;
}
.no-padding-right{
   padding-right: 0 !important;
}
.no-padding-bottom{
   padding-bottom: 0 !important;
}
.no-padding-left{
   padding-left: 0 !important;
}


/**
 * Floats
 */
.to-right{
	float: right !important;
}
.to-left{
	float: left !important;
}
.to-right-pl{
	@include to(right, pl);
}
.to-left-pl{
	@include to(left, pl);
}
.to-right-tp{
	@include to(right, tp);
}
.to-left-tp{
	@include to(left, tp);
}
.to-right-tl{
	@include to(right, tl);
}
.to-left-tl{
	@include to(left, tl);
}
.to-right-dt{
	@include to(right, dt);
}
.to-left-dt{
	@include to(left, dt);
}


/**
 * Clears
 */
.clear-right{
	clear: left;
}
.clear-left{
	clear: left;
}
.clear-both{
	clear: both;
}
.clearfix{
	&:after{
		content: '';
		display: block;
		clear: both;
	}
}


/**
 * Positioning
 */
.static{
	position: static;
}
.relative{
	position: relative;
}
.absolute{
	position: absolute;
}
.fixed{
	position: fixed;
}


/**
 * Horizontal ruler styles
 */
hr{
	height: 1px;
	margin-top: 30px;
	border-color: $border-color;

	@include breakpoint(pl){
		margin-top: 35px;
	}
	@include breakpoint(tp){
		margin-top: 40px;
	}
	@include breakpoint(tl){
		margin-top: 45px;
	}
	@include breakpoint(dt){
		margin-top: 50px;
	}
}


/**
 *	Absolute positioning
 */
.abs-valign{
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
}
.abs-top{
	position: absolute;
	top: 0;
}
.abs-right{
	position: absolute;
	right: 0;
}
.abs-bottom{
	position: absolute;
	bottom: 0;
}
.abs-left{
	position: absolute;
	left: 0;
}
.abs-center{
	@extend .abs-valign;
	right: 0;
	left: 0;
}
